<template>
  <v-dialog
    v-model="dialog"
    max-width="800">
    <template v-slot:activator="{ on }">
      <v-btn
        :disabled="disabled"
        color="primary"
        class="my-0 mx-0 white--text"
        v-on="on"
      >
        Pay
      </v-btn
      >
    </template>
    <v-card class="elevation-0 pa-3">
      <v-card-title>
        <span class="headline">Pay invoice</span>
      </v-card-title>

      <v-card-title class="pa-3">
        <v-layout
            v-if="passenger && passenger.user"
            row>
            <v-flex xs6>
                <div>
                    <p>Payment methods</p>
                    <user-payment
                    :active-mandate="parseInt(paymentData.mandate)"
                    :user-id="passenger.user.id"
                    :can-add-new-payment-method="false"
                    :can-delete="false"
                    @onSelectMandate="onSelectMandate" />
                </div>
            </v-flex>
            <v-flex xs6>
                <v-layout
                    row
                    wrap>
                    <v-flex
                        xs12
                        class="pa-2">
                        <InputHotKeyWrapper>
                          <v-text-field
                            v-model="paymentData.amount"
                            filled
                            type="Number"
                            placeholder="Amount"
                            hide-details/>
                        </InputHotKeyWrapper>
                    </v-flex>
                    <v-flex
                        xs12
                        class="pa-2">
                        <v-textarea
                            v-model="paymentData.description"
                            filled
                            placeholder="Description"
                            hide-details/>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
      </v-card-title>

      <v-card-actions class="pa-3 justify-end">
        <v-btn
          :disabled="loading"
          :loading="loading"
          color="primary"
          @click="payCustomInvoice"
        >
          Pay
        </v-btn>
        <v-btn
          color="secondary"
          @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import request from '../../helpers/request'
import _debounce from 'lodash/debounce'
import UserPayment from '../../components/Payment'
import PaymentMixin from '../../helpers/payment'


export default {
  mixins: [request, PaymentMixin],
  components: { UserPayment },
  props: {
    passenger: {
      type: Object,
      default: null
    },
    invoiceId: {
      type: Number,
      default: null
    },
    invoiceType: {
      type: String,
      default: 'normal'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      loading: false,
      paymentData: {
        amount: null,
        description: null,
        mandate: null
      }
    }
  },
  async beforeMount () {
    
  },
  computed: {
  },
  methods: {
    close () {
      this.dialog = false
    },
    async payCustomInvoice () {
        try {
            this.loading = true
            await this.createPayment({
                amount: this.paymentData.amount,
                description: this.paymentData.description,
                mandate: this.paymentData.mandate,
                invoiceId: this.invoiceId,
                invoiceType: this.invoiceType
            })

            this.$notify({
              group: 'topRight',
              type: 'success',
              text: 'aangepaste factuur succesvol toegevoegd'
            })
            this.dialog = false
            this.$emit('onPay')
        } catch (error) {} finally {
          this.loading = false
        }
    },
    onSelectMandate (value) {
      this.paymentData.mandate = value
    },
  }
}
</script>