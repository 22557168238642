<template>
  <v-container grid-list-xl>
    <app-card>
      <v-layout>
        <v-flex
          xs6>
          <div class="py-3">
            <add-custom-invoice
              :on-create="loadCustomInvoices"/>
          </div>
        </v-flex>
        <v-flex xs2></v-flex>
        <v-flex xs2>
          <v-checkbox
            v-model="isSentInvoice"
            :label="`Sent`"
            hide-details
            @change="loadCustomInvoices"
          />
        </v-flex>
        <v-flex xs2>
          <v-checkbox
            v-model="isSyncInvoice"
            :label="`Synced`"
            hide-details
            @change="loadCustomInvoices"
          />
        </v-flex>
      </v-layout>
      <v-data-table
        :headers="customInvoicesHeaders"
        :items="customInvoices"
        :loading="loading"
        class="elevation-1"
        hide-actions
        hide-default-header
        hide-default-footer
      >
        <template v-slot:items="props">
          <td class="text-center">
            <a
              :href="`/tables/custom-invoices/${props.item.id}`"
              class="custom-row-link">
              <div>
                <v-icon v-if="props.item.sentAt" color="green">mail</v-icon>
                <v-icon v-else color="red">mail_outline</v-icon>
              </div>
            </a>
          </td>
          <td class="text-center">
            <a
              :href="`/tables/custom-invoices/${props.item.id}`"
              class="custom-row-link">
              {{ props.item.id }}
            </a>
          </td>
          <td class="text-center">
            <a
              :href="`/tables/custom-invoices/${props.item.id}`"
              class="custom-row-link">
              {{ props.item.brand.name }}
            </a>
          </td>
          <td class="text-center">
            <router-link
              v-if="props.item.passenger"
              :to="`/tables/passengers/${props.item.passenger.id}`"
            >
              {{ props.item.passenger.name }}
            </router-link>
          </td>
          <td class="text-center">
            <router-link
              v-if="props.item.driver"
              :to="`/tables/drivers/${props.item.driver.id}`"
            >
              {{ props.item.driver.name }}
            </router-link>
          </td>
          <td class="py-2">
            <a
              :href="`/tables/custom-invoices/${props.item.id}`"
              class="custom-row-link">
              <div
                v-for="(line, index) in props.item.lines"
                :key="index">
                <div :class="`${index < props.item.lines.length - 1 ? 'line-border' : ''}`">
                  <b>Price:</b> {{ line.price }}<br> <b>Amount:</b> {{ line.amount }}<br> <b>VAT:</b> {{ line.VAT }}<br> <b>Name:</b> {{ line.name }}
                </div>
              </div>
            </a>
          </td>
          <td class="text-center">
            <a
              :href="`/tables/custom-invoices/${props.item.id}`"
              class="custom-row-link">
              {{ props.item.number }}
            </a>
          </td>
          <td class="text-center">
            <a
              :href="`/tables/custom-invoices/${props.item.id}`"
              class="custom-row-link">
              {{ gFunc.momentDateTime(props.item.invoicedAt) }}
            </a>
          </td>
          <td class="text-center">
            <a
              :href="`/tables/custom-invoices/${props.item.id}`"
              class="custom-row-link">
              <div v-if="props.item.sentAt">
                {{ gFunc.momentDateTime(props.item.sentAt) }}
              </div>
            </a>
          </td>
          <td class="text-center">
            <invoice-pdf
              :invoice="props.item"
              :link-icon="true"
            />
          </td>
          <td class="text-center">
            <payment-invoice
              :disabled="!props.item.pdf.file"
              :passenger="props.item.passenger"
              :invoice-id="props.item.id"
              invoice-type="custom"
              @onPay="loadCustomInvoices" />
            <v-btn
              :disabled="sendInvoiceLoader || !props.item.pdf.file"
              :loading="sendInvoiceLoader"
              color="secondary"
              @click="() => { sendCustomInvoice(props.item.id) }">
              Send
            </v-btn>

            <v-btn
              color="red"
              dark
              @click="onDeleteCustomInvoiceClick(props.item.id)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </app-card>
  </v-container>

</template>

<script>
import AddCustomInvoice from '../../../modules/components/AddCustomInvoice.vue'
import PaymentInvoice from '../../../modules/components/PaymentInvoice.vue'
import Request from '../../../helpers/request'
import Brands from '../../../helpers/brands'
import InvoicePdf from 'Components/Invoice/InvoicePdf'

export default {
  components: { AddCustomInvoice, PaymentInvoice, InvoicePdf },
  mixins: [Request, Brands],
  data () {
    return {
      customInvoicesHeaders: [
        { text: '', sortable: false },
        { text: 'Id', align: 'center', sortable: false },
        { text: 'Brand', align: 'center', sortable: false },
        { text: 'Passenger', align: 'center', sortable: false },
        { text: 'Driver', align: 'center', sortable: false },
        { text: 'Lines', align: 'left', sortable: false },
        { text: 'Invoice number', align: 'center', sortable: false },
        { text: 'Invoiced At', align: 'center', sortable: false },
        { text: 'Sent At', align: 'center', sortable: false },
        { text: 'PDF', align: 'center', sortable: false },
        { text: ' ', align: 'center', sortable: false }
      ],
      customInvoices: [],
      loading: false,
      sendInvoiceLoader: false,
      isSentInvoice: false,
      isSyncInvoice: false
    }
  },
  beforeMount () {
    this.loadCustomInvoices()
  },
  methods: {
    async sendCustomInvoice (invoiceId) {
      this.sendInvoiceLoader = true
      
      try {
        await this.request('POST', `/custom-invoices/${invoiceId}/send`, {})
        this.sendInvoiceLoader = false
        this.loadCustomInvoices()
      } catch (error) {
        this.sendInvoiceLoader = false
      }
    },
    async getCustomInvoices (params = {}) {
      return this.request('GET', `/custom-invoices`, { params })
    },
    async onDeleteCustomInvoiceClick (id) {
      try {
        await this.deleteCustomInvoice(id)
        await this.loadCustomInvoices()
      } catch (error) {

      }
    },
    async deleteCustomInvoice (id) {
      try {
        await this.request('DELETE', `/custom-invoices/${id}`)
      } catch (error) {

      }
    },
    async loadCustomInvoices () {
      try {
        this.loading = true

        const { data: { data } } = await this.getCustomInvoices({
          sentAt: this.isSentInvoice ? 1 : 0,
          synced: this.isSyncInvoice ? 1 : 0
        })

        this.customInvoices = data
      } catch (error) {

      }

      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .custom-row-link {
    color: inherit;
  }
</style>
