<template>
  <v-dialog
    v-model="dialog">
    <template v-slot:activator="{ on }">
      <v-btn
        color="primary"
        class="my-0 mx-0 white--text"
        v-on="on"
      >
        Add custom invoice
      </v-btn
      >
    </template>
    <v-card class="elevation-0 pa-3">
      <v-card-title>
        <span class="headline">Add custom invoice</span>
      </v-card-title>

      <v-card-title class="pa-3">
        <v-layout
          row
          wrap>
          <v-flex
            xs4
            class="pos-relative pa-2">
            <passengers-input
              v-if="enabledBrandsIds && enabledBrandsIds.length"
              ref="passengersInput"
              clearable
              :disabled="Boolean(passengerPropId)"
              v-model="passengerPropId"
              :brand-list="enabledBrandsIds"
              @changePassenger="changePassenger"/>
          </v-flex>
          <v-flex
            xs4
            class="pa-2">
            <v-select
              :disabled="!selectedPassenger"
              :items="selectedPassengerBrands"
              v-model="customInvoice.brandId"
              item-value="id"
              item-text="name"
              label="Select brand"
              hide-details
              @change="getInvoiceNumber"
            />
          </v-flex>
          <v-flex
            xs4
            class="pos-relative pa-2">
            <drivers-input
              v-if="enabledBrandsIds && enabledBrandsIds.length"
              ref="driversInput"
              :disabled="!selectedPassengerBrandComp.length"
              :brand-list.sync="selectedPassengerBrandComp"
              @changeDriver="changeDriver"/>
          </v-flex>
          <v-flex
            xs4
            class="pa-2">
            <v-text-field
              :disabled="!selectedPassengerBrandComp.length"
              v-model="customInvoice.invoiceNumber"
              filled
              placeholder="Number"
              hide-details/>
          </v-flex>
          <v-flex
            xs4
            class="pa-2">
            <v-text-field
              v-model="customInvoice.regarding"
              filled
              placeholder="Description"
              hide-details/>
          </v-flex>
          <v-flex
            xs4
            class="pa-2">
            <v-text-field
              v-model="customInvoice.invoicedAt"
              type="datetime-local"
              filled
              placeholder="Invoiced At"
              hide-details/>
          </v-flex>
          <v-flex
            xs12
            class="px-2 pt-3">
            <h4 class="my-0 mt-3">lines:</h4>
          </v-flex>
          <v-flex
            xs12
            class="px-2">
            <div
              class="full-width"
              v-for="(line, index) in customInvoice.lines"
              :key="index">
              <v-layout
                row
                wrap>
                <v-flex
                  xs2
                  class="py-2">
                  <div class="pr-2">
                    <v-text-field
                    v-model="line.name"
                    filled
                    class="pa-0"
                    placeholder="Name"
                    hide-details/>
                  </div>
                </v-flex>
                <v-flex
                  xs2
                  class="py-2">
                  <div class="px-2">
                    <InputHotKeyWrapper>
                      <v-text-field
                        v-model="line.price"
                        filled
                        class="pa-0"
                        type="number"
                        placeholder="Price"
                        hide-details/>
                    </InputHotKeyWrapper>
                  </div>
                </v-flex>
                <v-flex
                  xs2
                  class="py-2">
                  <div class="px-2">
                    <InputHotKeyWrapper>
                      <v-text-field
                        v-model="line.amount"
                        filled
                        class="pa-0"
                        type="number"
                        placeholder="Amount"
                        hide-details/>
                    </InputHotKeyWrapper>
                  </div>
                </v-flex>
                <v-flex
                  xs2
                  class="py-2">
                  <div class="pl-2">
                    <v-select
                      :disabled="!customInvoice.brandId"
                      :items="vatList"
                      v-model="line.exactVatCode"
                      item-value="id"
                      item-text="description"
                      label="VAT"
                      hide-details
                      class="pt-0"
                    />
                  </div>
                </v-flex>
                <v-flex
                  xs2
                  class="py-2">
                  <div class="pl-2">
                    <v-select
                      :disabled="!customInvoice.brandId"
                      :items="exactItemsList"
                      v-model="line.exactItem"
                      item-value="id"
                      item-text="description"
                      label="Exact item"
                      hide-details
                      class="pt-0"
                    />
                  </div>
                </v-flex>
                <v-flex
                  xs2
                  class="py-2">
                  <div class="pl-3">
                    <v-btn
                      dark
                      color="red"
                      class="ma-0"
                      small
                      @click="() => { removeLine(index) }">
                      remove
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
          <v-flex
            xs12
            class="pa-2">
            <div>
              <v-btn
                dark
                color="green"
                class="ma-0"
                small
                @click="addLine">
                Add line
              </v-btn>
            </div>
          </v-flex>
        </v-layout>
      </v-card-title>

      <v-card-actions class="pa-3 justify-end">
        <v-btn
          :disabled="loading"
          :loading="loading"
          color="primary"
          @click="createCustomInvoice"
        >
          Save
        </v-btn>
        <v-btn
          color="secondary"
          @click="close">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import request from '../../helpers/request'
import _debounce from 'lodash/debounce'
import PassengersInput from './PassengersInput.vue'
import DriversInput from './DriversInput.vue'
import { BRAND } from '../../constants/common'
import Brands from '../../helpers/brands'

const customInvoiceTemplate = {
  brandId: null,
  passengerId: null,
  driverId: null,
  lines: [],
  invoiceNumber: null,
  invoiceAt: null,
  regarding: null,
  loading: false
}
export default {
  mixins: [request, Brands],
  components: { PassengersInput, DriversInput },
  props: {
    passengerPropId: {
      type: Number,
      default: null
    },
    onCreate: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      dialog: false,
      loading: false,
      brandList: [],
      customInvoice: { ...customInvoiceTemplate },
      searchPassengerName: '',
      passengersList: [],
      passengersLoading: false,
      selectedPassenger: null,
      selectedPassengerBrand: null,
      vatList: [],
      exactItemsList: [],
      enabledBrands: [BRAND.EDRIVERS]
    }
  },
  async beforeMount () {
    this.beforeMountFunction()
  },
  computed: {
    selectedPassengerBrands () {
      if (this.selectedPassenger && this.selectedPassenger.brands) {
        return this.selectedPassenger && this.selectedPassenger.brands.filter(item => this.enabledBrands.includes(item.name))
      }
      return []
    },
    selectedPassengerBrandComp () {
      if (this.selectedPassengerBrand) {
        return [this.selectedPassengerBrand]
      }
      return []
    },
    enabledBrandsIds () {
      if (this.brandList) {
        return this.brandList.filter(item => this.enabledBrands.includes(item.name)).map(item => item.id)
      }
      return null
    }
  },
  watch: {
    'customInvoice.brandId': {
      handler (val) {
        this.getVatList()
        this.getExactItemsList()
        this.selectedPassengerBrand = this.brandList.find(item => item.id === val)
      },
      deep: true
    }
  },
  methods: {
    async beforeMountFunction () {
      if (this.passengerPropId) {
        this.getPassenger(this.passengerPropId)
      }
      
      this.brandList = await this.fetchBrands()
    },
    async getInvoiceNumber () {
      if (this.customInvoice.brandId && this.customInvoice.passengerId) {
        try {
          this.request('GET', `/custom-invoices/number/next?passenger=${this.customInvoice.passengerId}&brand=${this.customInvoice.brandId}`, {}, ({ data }) => {
            if (data && data.number) {
              this.customInvoice.invoiceNumber = data.number
            }
          })
        } catch (error) {
          console.log(error)
        }
      }
    },
    async getVatList () {
      if (this.customInvoice.brandId) {
        try {
          this.request('GET', `/exact-online/vat-codes?brand=${this.customInvoice.brandId}`, {}, ({ data }) => {
            if (data) {
              this.vatList = data
            }
          })
        } catch (error) {
          console.log(error)
        }
      }
    },
    async getExactItemsList () {
      if (this.customInvoice.brandId) {
        try {
          this.request('GET', `/exact-online/items?brand=${this.customInvoice.brandId}`, {}, ({ data }) => {
            if (data) {
              this.exactItemsList = data
            }
          })
        } catch (error) {
          console.log(error)
        }
      }
    },
    close () {
      this.dialog = false
    },
    changePassenger (inputPassenger) {
      this.customInvoice.passengerId = inputPassenger.id
      this.getPassenger(inputPassenger.id)
    },
    changeDriver (inputDriver) {
      this.customInvoice.driverId = inputDriver.id
    },
    addLine () {
      this.customInvoice.lines.push({ name: null, price: null, amount: null, exactVatCode: null, exactItem: null })
    },
    removeLine (indexLine) {
      this.customInvoice.lines.splice(indexLine, 1)
    },
    clearInvoiceTemplate () {
      if (!this.passengerPropId) {
        this.$refs.passengersInput.reset()
      }

      this.$refs.driversInput.reset()
      this.customInvoice = { ...customInvoiceTemplate }
      this.customInvoice.lines = []
      this.selectedPassengerBrand = null
      this.selectedPassenger = null
      this.beforeMountFunction()
    },
    async createCustomInvoice () {
      this.loading = true
      try {
        const body = {
          data: {
            brand: this.customInvoice.brandId,
            passenger: this.customInvoice.passengerId,
            driver: this.customInvoice.driverId,
            lines: this.customInvoice.lines,
            number: this.customInvoice.invoiceNumber,
            regarding: this.customInvoice.regarding,
            invoicedAt: this.customInvoice.invoicedAt
          }
        }

        await this.request('POST', `/custom-invoices`, body)
        this.onCreate()

        this.clearInvoiceTemplate()
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'aangepaste factuur succesvol toegevoegd'
        })
        this.dialog = false
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    getPassenger (id) {
      if (id) {
        this.request('GET', `/passengers/${id}`, {}, ({ data }) => {
            this.selectedPassenger = data

            if (this.customInvoice.passengerId !== id) {
              this.customInvoice.passengerId = id
            }

            if (this.selectedPassengerBrands && this.selectedPassengerBrands.length) {
                this.customInvoice.brandId = this.selectedPassengerBrands[0].id
                this.selectedPassengerBrand = this.selectedPassengerBrands[0]
            } else {
                this.selectedPassengerBrand = null
            }
            this.getInvoiceNumber()
        })
      } else {
        this.selectedPassenger = null
        this.selectedPassengerBrand = null
      }

    }
  }
}
</script>
